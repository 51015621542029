@import url("https://fonts.googleapis.com/css2?family=Notable&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

span {
  position: absolute;
  top: 50%;
  right: 40px;
  font-size: 30px;
  transform: translateY(-50%);
  z-index: 1;
  display: none;
  cursor: pointer;
}

.achors {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.achors a {
  text-decoration: none;
}

.pres {
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
}

.pres h3 {
  position: relative;
  left: 10px;
  color: blue;
  text-decoration: none;
  font-family: Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-text-stroke-width: 0.9px;
  -webkit-text-stroke-color: red;
  display: none;
}

.achors nav {
  display: flex;
}

.achors nav a {
  position: relative;
  margin-right: 60px;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
}

.achors nav a:hover {
  text-decoration: underline;
  color: red;
}

html {
  font-family: "Urbanist", sans-serif;
  background: white;
}

h1,
h2,
b {
  font-weight: 100;
  color: red;
}

.logo-center {
  position: relative;
  margin: 0px auto 0px auto;
  width: max-content;
  height: max-content;
}
header nav {
  display: none;
}

header a {
  text-decoration: none;
  color: black;
}

.logo-control {
  position: relative;
  margin: 0px auto 0px auto;
  width: max-content;
  height: max-content;
}

#logo {
  position: relative;
  width: 350px;
  height: 80px;
  object-fit: cover;
}

.quick {
  position: absolute;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 0px;
  display: block;
  background: white;
  width: max-content;
  height: max-content;
  z-index: 2;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.quick a {
  padding-left: 10px;
  margin-top: 10px;
  display: block;
}

#down {
  position: absolute;
  top: 0px;
  left: 85px;
  z-index: 10;
}

#prod-nav-one:hover {
  text-decoration: none;
  color: black;
}

.slider {
  position: relative;
  margin: 10px auto 0px auto;
  width: 75%;
  height: 500px;
}

.image {
  position: relative;
  margin: 0px auto 0px auto;
  width: 100%;
  height: 100%;
}

.shadow {
  position: absolute;
  padding: 40px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.shadow h1 {
  position: relative;
  top: 20px;
  font-family: "Notable", sans-serif;
  font-size: 40px;
  font-weight: 100;
  color: white;
  width: 700px;
  height: 300px;
  z-index: 3;
}

.shadow p {
  top: 20px;
  color: white;
}

.buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.buttons button {
  width: 70px;
  height: 40px;
}

#img {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}

header {
  padding: 15px;
  display: flex;
  align-items: center;
}

.products {
  margin: 40px auto 0px auto;
}

.products h2,
.know h2 {
  position: relative;
  text-align: center;
  padding: 20px;
  padding-left: 0px;
}

.showcase {
  position: relative;
  margin: 0px auto 0px auto;
  width: 90%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#products-cover {
  position: relative;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

#products-mining {
  position: relative;
  width: 160px;
  height: 160px;
  border-style: solid;
  border-width: 5px;
  border-color: transparent;
}

.case {
  position: relative;
  margin: 30px auto 0px auto;
  padding: 15px;
  padding-bottom: 30px;
  text-align: left;
  width: 350px;
  height: max-content;
  border-bottom-style: solid;
  border-width: 10px;
  border-color: red;
}

.case-img {
  position: relative;
  margin: 20px auto 0px auto;
  padding: 15px;
  padding-bottom: 30px;
  text-align: left;
  width: 200px;
  height: max-content;
  border-bottom-style: solid;
  border-width: 10px;
  border-color: red;
}

.level {
  position: relative;
  margin: 0px auto 0px auto;
  width: 200px;
  height: max-content;
}

.case-img p {
  padding-top: 20px;
  color: black;
  line-height: 25px;
}

.prod-nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: max-content;
}

#prev {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 60px;
  cursor: pointer;
  z-index: 2;
}

#next {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 60px;
  cursor: pointer;
  z-index: 2;
}

.case h2 {
  padding-left: 0px;
  text-align: left;
}

.case p {
  position: relative;
  padding-top: 10px;
  font-weight: 100;
}

.items {
  position: relative;
  margin: 40px auto 0px auto;
  width: max-content;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 15px;
  grid-column-gap: 15px;
}

.items p {
  text-align: center;
}

#items {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.description {
  position: relative;
  padding: 30px;
  margin: 40px auto 0px auto;
  width: 80%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: lightgray;
}

.box {
  position: relative;
  margin: 30px auto 0px auto;
  width: 250px;
  height: max-content;
}

.box h3 {
  padding-left: 15px;
  font-weight: 100;
}

.box p {
  padding: 15px;
  color: black;
  line-height: 20px;
}

.know {
  margin: 40px auto 0px auto;
  width: 75%;
}

.base {
  position: relative;
  padding-left: 0px;
  display: flex;
}

.base-img {
  position: relative;
  margin: 0px auto 0px auto;
  width: 300px;
  height: 300px;
}

.base-text {
  position: relative;
  padding: 10px;
  padding-left: 0px;
  margin: 0px auto 0px auto;
  width: 400px;
  height: 300px;
}

.case p,
.base-text p,
.shadow p {
  color: black;
  line-height: 20px;
}

.shadow p {
  color: white;
}

.base-text h2 {
  text-align: left;
}

#bio {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.base-text button,
.foot button,
.case button,
.search-bar button {
  position: relative;
  margin-top: 20px;
  width: 140px;
  height: 50px;
  background: rgb(12, 76, 196);
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-weight: 100;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.foot button {
  top: 0px;
  width: 140px;
  height: 40px;
}

.foot input {
  position: relative;
  margin-right: 10px;
  padding-left: 10px;
  width: 250px;
  height: 40px;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  outline: none;
}

.about {
  position: relative;
  margin: 40px auto 0px auto;
  padding: 0px;
  padding-bottom: 0px;
  width: 100%;
  height: max-content;
  font-weight: 100;
}

.about h2 {
  padding: 10px;
}

.directions {
  position: relative;
  padding: 30px;
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.news {
  position: relative;
  margin: 0px auto 0px auto;
  padding: 20px;
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(31, 30, 30);
}

.news h3,
p {
  line-height: 10px;
  font-weight: 100;
  color: white;
}

.news p {
  font-size: 15px;
}

.menu {
  position: relative;
  margin: 0px auto 0px auto;
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu nav a {
  position: relative;
  padding: 20px;
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.menu nav a:hover {
  text-decoration: underline;
  color: red;
}

.search-bar {
  position: relative;
  margin: 20px auto 20px auto;
  width: max-content;
  height: max-content;
}

.search-bar input {
  position: relative;
  margin-right: 10px;
  padding-left: 10px;
  width: 250px;
  height: 40px;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  outline: none;
}

.search-bar button {
  width: 100px;
  height: 40px;
}

.brief {
  position: relative;
  margin: 30px auto 0px auto;
  width: 500px;
  height: max-content;
}

.brief p {
  padding-top: 20px;
  line-height: 20px;
  color: black;
}

.brief h2 {
  padding-top: 20px;
}

.about-cover {
  position: relative;
  margin: 20px auto 0px auto;
  width: 100%;
  height: 300px;
}

#about-cover {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#about-cover-sm {
  position: relative;
  width: 100%;
  height: 100%;
}

.about-cover p {
  color: red;
}

.clientele {
  position: relative;
  margin: 0px auto 0px auto;
  padding: 20px;
  padding-left: 0px;
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
}

#clientele {
  position: relative;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-style: solid;
  border-width: 5px;
  border-color: red;
}

.clientele-wrap {
  position: relative;
  margin: 0px auto 0px auto;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
}

b {
  color: black;
}

ul,
li {
  list-style: none;
}

li {
  line-height: 25px;
}
@media screen and (max-width: 1280px) {
  .showcase {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  #logo {
    width: 280px;
    height: 70px;
  }
  html {
  }
  .directions,
  .description {
    grid-template-columns: repeat(3, 1fr);
  }

  .directions {
    grid-row-gap: 30px;
  }

  .know,
  .showcase {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .slider {
    width: 95%;
  }
}

@media screen and (max-width: 900px) {
  #logo {
    width: 200px;
    height: 50px;
  }
  .pres h3 {
    font-size: 26px;
    width: 300px;
  }
  .achors a {
    display: none;
  }
  #prod-nav-one:hover {
    color: black;
  }

  #prod-nav-one a {
    margin-top: 15px;
  }

  #down {
    display: none;
  }
  .achors nav {
  }

  .achors nav a {
    color: white;
  }

  .achors nav a:hover {
    color: white;
  }

  span {
    display: block;
  }

  header nav {
    display: none;
  }
  html {
  }

  .shadow h1 {
    font-size: 35px;
    width: 75%;
  }

  .case {
    width: 200px;
  }

  #items {
    width: 150px;
    height: 150px;
  }
  .description {
    padding: 0px;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .box {
    width: 90%;
    height: max-content;
  }
}

@media screen and (max-width: 800px) {
  .slider {
    width: 100%;
  }
  #down {
    display: none;
  }
  .achors nav {
  }

  .achors nav a {
    color: white;
  }

  .achors nav a:hover {
    color: white;
  }

  span {
    display: block;
  }

  .shadow h1 {
    font-size: 35px;
    width: 85%;
  }

  header nav {
    display: block;
  }

  .showcase,
  .base {
    display: block;
  }

  .case,
  .base-img,
  .base-text,
  .description {
    width: 300px;
  }

  #bio {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  #items {
    width: 200px;
    height: 200px;
  }
  .directions {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 720px) {
  #client-mobile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .slider {
    height: 450px;
  }

  .shadow h1 {
    font-size: 35px;
    width: 100%;
  }
  .directions {
    grid-template-columns: repeat(1, 1fr);
  }

  .news {
    padding: 40px;
    display: block;
  }

  .news p {
    padding-bottom: 30px;
  }

  .news input {
    width: 99%;
  }

  .news button {
    top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .search-bar {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .search-bar input {
    width: 100%;
  }

  #clientele {
    width: 160px;
    height: 160px;
  }
  .brief {
    width: 95%;
  }

  .clientele {
    grid-template-columns: repeat(2, 1fr);
  }
  .pres h3 {
    font-size: 22px;
    width: 200px;
  }
  .slider {
    height: 400px;
  }
  .shadow {
    padding: 15px;
  }
  .shadow h1 {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 28px;
    width: 95%;
  }

  .shadow p {
    margin-top: 0px;
  }
  #items {
    width: 130px;
    height: 130px;
  }
}

@media screen and (max-width: 280px) {
  #logo {
    width: 170px;
    height: 40px;
  }
  #clientele {
    width: 115px;
    height: 115px;
  }
  .clientele {
    grid-template-columns: repeat(2, 1fr);
  }
  .search-bar {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .search-bar input {
    width: 100%;
  }
  .pres h3 {
    font-size: 16px;
    width: 100px;
  }
  .shadow h1 {
    font-size: 24px;
  }

  .base-img {
    width: 90%;
    display: block;
  }

  #bio {
    width: 260px;
    height: 260px;
  }

  .base-text {
    width: 95%;
    height: 400px;
  }

  .base-text .button {
    margin-top: 20px;
  }

  #bio {
    width: 100%;
  }

  .case,
  .base,
  .know {
    width: 90%;
  }

  .news h3 {
    line-height: 30px;
  }

  .news p {
    line-height: 25px;
  }
}
